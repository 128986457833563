var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { size: "small", allowClear: "", placeholder: "小币种" },
              model: {
                value: _vm.params.coinItemName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinItemName", $$v)
                },
                expression: "params.coinItemName"
              }
            }),
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", width: "180px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  "filter-option": _vm.untils.filterOption,
                  dropdownMenuStyle: { "max-height": "350px" },
                  allowClear: "",
                  showSearch: "",
                  size: "small",
                  placeholder: "请选择大币种"
                },
                on: { search: _vm.searchDataByList },
                model: {
                  value: _vm.params.coinId,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "coinId", $$v)
                  },
                  expression: "params.coinId"
                }
              },
              _vm._l(_vm.coinList, function(items) {
                return _c(
                  "a-select-option",
                  { key: items.id, attrs: { value: items.id } },
                  [_vm._v(_vm._s(items.coinKindName))]
                )
              }),
              1
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "a-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.openModal({})
                  }
                }
              },
              [_vm._v("新增小币种")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "table-content",
          staticStyle: { "padding-top": "20px" }
        },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record) {
                return record.id
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "tags",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    _vm._l(row.tagList, function(tag, key) {
                      return _c(
                        "a-tag",
                        { key: key, attrs: { color: "blue" } },
                        [_vm._v(_vm._s(tag.coinTagName))]
                      )
                    }),
                    1
                  )
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.innerTitle,
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "大币种", prop: "coinKindSid" } },
                [
                  _c(
                    "a-select",
                    {
                      ref: "addse",
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      on: { search: _vm.searchData },
                      model: {
                        value: _vm.forms.coinKindSid,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "coinKindSid", $$v)
                        },
                        expression: "forms.coinKindSid"
                      }
                    },
                    _vm._l(_vm.currency_big_list, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.sid } },
                        [
                          _vm._v(_vm._s(item.coinKindName)),
                          item.dynastyName
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.dynastyName))
                              ])
                            : _vm._e(),
                          item.dynastyName
                            ? _c("span", [_vm._v(" " + _vm._s(item.rulerName))])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "coinKindItemName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "名称" },
                    model: {
                      value: _vm.forms.coinKindItemName,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "coinKindItemName", $$v)
                      },
                      expression: "forms.coinKindItemName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标签" } },
                [
                  _vm._l(_vm.forms.tagList, function(_item, _index) {
                    return _c(
                      "a-tag",
                      {
                        key: _index,
                        attrs: { closable: "" },
                        on: {
                          close: function($event) {
                            return _vm.removeTag(_index)
                          },
                          click: function($event) {
                            return _vm.confirmTag(_item, _index)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_item.coinTagName))]
                    )
                  }),
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.confirmTag({})
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                ],
                2
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "图片" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture",
                        "default-file-list": _vm.fileList
                      },
                      on: { change: _vm.uploadStateChange }
                    },
                    [
                      _c(
                        "a-button",
                        { attrs: { disabled: _vm.fileList.length >= 1 } },
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v("选择图片")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "新增表签",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.cancelTags, ok: _vm.addTag },
          model: {
            value: _vm.tagVisible,
            callback: function($$v) {
              _vm.tagVisible = $$v
            },
            expression: "tagVisible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "tagforms",
                attrs: { model: _vm.tagForms, rules: _vm.tagRules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "coinTagName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "名称" },
                    model: {
                      value: _vm.tagForms.coinTagName,
                      callback: function($$v) {
                        _vm.$set(_vm.tagForms, "coinTagName", $$v)
                      },
                      expression: "tagForms.coinTagName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标签分类", prop: "coinTagCategory" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "标签分类" },
                      model: {
                        value: _vm.tagForms.coinTagCategory,
                        callback: function($$v) {
                          _vm.$set(_vm.tagForms, "coinTagCategory", $$v)
                        },
                        expression: "tagForms.coinTagCategory"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("别名标签")
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("钱币性质标签")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }