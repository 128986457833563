<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input
                    v-model="params.coinItemName"
                    size='small'
                    allowClear
                    style="width:150px;margin:5px;"
                    placeholder='小币种'
                ></a-input>
              <!-- 选择大币种 -->
              <a-select
                  :dropdownMatchSelectWidth="false"
                  :filter-option="untils.filterOption"
                  :dropdownMenuStyle="{'max-height': '350px'}"
                  allowClear
                  showSearch
                  size="small"
                  @search='searchDataByList'
                  style="margin: 5px; width: 180px"
                  v-model="params.coinId"
                  placeholder="请选择大币种"
              >
                <a-select-option
                    v-for="items of coinList"
                    :key="items.id"
                    :value="items.id"
                >{{ items.coinKindName }}</a-select-option>
              </a-select>
                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>
            <div>
                <a-button size='small' @click="openModal({})">新增小币种</a-button>
            </div>
        </div>
        <div class="table-content" style="padding-top:20px;">
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="record=>record.id">
                <div slot="tags" slot-scope="row">
                    <a-tag v-for="(tag,key) in row.tagList" :key="key" color="blue">{{ tag.coinTagName }}</a-tag>
                </div>
                 <div slot="setup" slot-scope="row">
                    <a @click="openModal(row)" style='margin-right:5px'>编辑</a>
                    <a-popconfirm
                        title="Are you sure delete this item?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row.id)"
                    >
                    <a style='margin-left:5px'>删除</a>
                    </a-popconfirm>
                </div>
                
            </a-table>
        </div>

        <a-modal v-model="visible" :title="innerTitle" ok-text="确认" cancel-text="取消" @cancel='resetForms' @ok="hideModal">
            <a-form-model
                ref="ruleForm"
                :model="forms"
                :rules="rules"
                v-bind="layout"> 
                <a-form-model-item label="大币种" prop="coinKindSid" >
                    <a-select @search='searchData' ref='addse' v-model="forms.coinKindSid" :filter-option="untils.filterOption" showSearch placeholder='请选择'>
                        <a-select-option v-for="item of currency_big_list" :key="item.id" :value='item.sid'>{{item.coinKindName}}<span v-if="item.dynastyName"> {{item.dynastyName}}</span><span v-if="item.dynastyName"> {{item.rulerName}}</span></a-select-option>
                    </a-select>
                </a-form-model-item>  
                <a-form-model-item label="名称" prop="coinKindItemName" >
                    <a-input placeholder='名称' v-model="forms.coinKindItemName"/>
                </a-form-model-item>

                <a-form-model-item label='标签'>
                    <a-tag closable @close='removeTag(_index)' v-for="(_item,_index) in forms.tagList" :key="_index" @click="confirmTag(_item,_index)">{{_item.coinTagName}}</a-tag>
                    <a-button @click="confirmTag({})" size='small'>添加</a-button>
                </a-form-model-item>

                <a-form-model-item label='图片'>
                    <a-upload
                    :key="imgKey"
                    :action="ip+'/file/upload'"
                    list-type="picture"
                    :default-file-list="fileList"
                    @change="uploadStateChange"
                    >
                        <a-button :disabled='fileList.length>=1'> <a-icon type="upload" />选择图片</a-button>
                    </a-upload>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <a-modal v-model="tagVisible" title="新增表签" ok-text="确认" cancel-text="取消" @cancel='cancelTags' @ok="addTag">
            <a-form-model
                ref="tagforms"
                :model="tagForms"
                :rules="tagRules"
                v-bind="layout">   
                <a-form-model-item label="名称" prop="coinTagName" >
                    <a-input placeholder='名称' v-model="tagForms.coinTagName"/>
                </a-form-model-item>

                <a-form-model-item label='标签分类' prop='coinTagCategory'>
                    <a-select placeholder='标签分类' v-model="tagForms.coinTagCategory">
                        <a-select-option :value='1'>别名标签</a-select-option>
                        <a-select-option :value='2'>钱币性质标签</a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import untils from "@/untils"
export default {
    data(){
        return {
          untils,
          coinList: [],
            columns:[
                {title:'ID',dataIndex:'id'},
                {title:'名称',dataIndex:'coinKindItemName'},
                {title:'大币种',dataIndex:'coinKindName'},
                {title:'标签',scopedSlots:{customRender:'tags'}},
                {title:'操作',align:'center',scopedSlots: { customRender: 'setup' },},
            ],
            data:[],
            layout:{
                labelCol: { span: 4 },//标签占5份  共24份
                wrapperCol: { span:20 },
            },
            forms:{},
            tagForms:{},
            rules:{
                coinKindSid:[{ required: true, message: 'please change', trigger: 'change' }],
                coinKindItemName:[{ required: true, message: 'please enter coinKindItemName', trigger: 'blur' }],
                tagList:[{ required: true, message: 'please enter', trigger: 'change' }],
            },
            tagRules:{
                coinTagCategory:[{ required: true, message: 'please change', trigger: 'change' }],
                coinTagName:[{ required: true, message: 'please change', trigger: 'change' }], 
            },
            visible:false,
            tagVisible:false,
            innerTitle:'',
            currency_big_list:[],
            fileList:[],
            currency_type_list:[],
            params:{
              pageNum:1,
              pageSize:10,
              coinId:'',
              coinItemName: ''
            },
            IO:0,
            tagCurrent:'',
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            imgKey:'',
            
        }
    },
    computed:{
        ...mapState(['ip'])
    },
    watch:{
        visible(){
            this.imgKey = this.visible ? '' : Math.random() 
        }
    },
    created(){
        this.getData(this.params)
        this.axios('/dq_admin/category/list').then(res=>{
            const {records} = res.data
            this.currency_type_list = records || []
        })
    },
    methods:{
      /** 获取大币种列表 */
      searchDataByList(inner){
        this.axios('/dq_admin/kind/list',{params:{
            coinName: inner
          }}).then(res=>{
          if(res.status == '200'){
            const {records} = res.data
            this.coinList = records
          }
        })
      },
        openModal(row){
            const {id} = row
            const type = id ? 1 : 0
            this.IO = type
            this.visible = true
            const text = type == 0 ? '新增小币种' : '编辑小币种'
            this.innerTitle = text
            this.forms = id ? JSON.parse(JSON.stringify(row)) : {tagList:[]};
            const {coinKindSid,coinKindName} = this.forms
            if(coinKindSid){
                this.currency_big_list = [{id:1,sid:coinKindSid,coinKindName}]
            }
        },
        hideModal(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        submit(forms){
            const post_url = this.IO == 1 ? '/dq_admin/kinditem/edit' : '/dq_admin/kinditem/add'
            this.axios.post(post_url,forms).then(res=>{
                if(res.status == 'SUCCESS'){
                    this.$message.success(res.message)
                    this.visible = false
                    this.fileList = []
                    this.getData(this.params)
                }
            })
        },
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/kinditem/list',{params:params})
            this.loading = false
            const {records,total} = res.data
           
            this.data = records
            this.pagination.total = total 
        },
        search(){
            this.params.pageNum = 1
            this.getData(this.params)
        },
        async remove(params){
            const res = await this.axios('/dq_admin/kinditem/delKindItemById',{params:{id:params}})
            if(res.status == 'SUCCESS'){
                this.$message.success(res.message)
                this.getData(this.params)
            }
        },
        addTag(){
            this.$refs.tagforms.validate(valid => {
                if(valid){
                    if(typeof this.tagCurrent == 'number'){
                        this.forms.tagList.splice(this.tagCurrent,1,this.tagForms)
                    }else{
                        this.forms.tagList.push(this.tagForms)
                    }
                    this.tagVisible = false
                }else{
                    return false;
                }
            });
        },
        confirmTag(row,index){
            this.tagVisible = true
            this.tagForms = row
            this.tagCurrent = index || index === 0 ? index : ''
        },
        resetForms(){
            this.forms = {}
            this.fileList = []
            this.$refs.ruleForm.resetFields();
        },
        removeTag(n){
            this.forms.tagList.splice(n,1)
        },
        cancelTags(){
            this.tagForms = {}
            this.$refs.tagforms.resetFields()
        },
         uploadStateChange(res){
            try{//当前为异步操作，为防止控制台报错
                this.fileList = res.fileList
                const {data} = res.file.response
                this.forms.coinKindItemIcon = data
            }catch(e){} 
        },
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.pageNum = current
            this.params.pageSize = pageSize
            this.getData(this.params)
        },
        searchData(inner){
            this.axios('/dq_admin/kind/list',{params:{
                coinName: inner
            }}).then(res=>{
                if(res.status == '200'){
                    const {records} = res.data
                    this.currency_big_list = records
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>